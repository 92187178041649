
/* custom.css */
.st{
    font-size:13px !Important;
}
.bc{
    background-color:#f5f5f5 !Important;
    max-width:315px;
    line-height: 130% !Important;
}

.likes{
    width:20px;
    border:none;
}
.likesbtn{
    border:none;
    background-color:#f5f5f5 !Important;
}
.mybutton{
    font-size:20px !Important;
    padding:300px !Important;
}

.leftStyling {
    left:15px !Important;
}

.rd-navbar-fixed .rd-navbar-brand img {
    width:240px !Important;
    height:240px !Important;
    max-width: 240px !Important;
    max-height: 240px !Important;
}

